import styled from 'styled-components';

const BoldFont = 'Rubik-Medium';
const RegularFont = 'Rubik-Light';
const MediumFont = 'Rubik-Regular';

const Section = styled.div`
  float: left;
  width: 100%;
  background-color: #EBF7FE;
  padding: 50px 90px 50px 98px;
  > div {
    float: left;
    width: 47%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding-right: 50px;
    padding-bottom: 15px;
     > p {
        font-family: ${BoldFont};
        font-size: 17px;
        line-height: 26px;
        color: #71A147;
        letter-spacing: 0;
        font-weight: 400;
        margin-top: 10px;
        margin-bottom: 3px;
     }
    > h1 {
        font-size: 37px;
        color: #3F4556;
        font-family: ${BoldFont};
        letter-spacing: 0;
        margin-top: 15px;
        line-height: 40px;
        font-weight: 700;
        word-break: break-word;
        width: 100%;
    } 
  }
  > div:last-child{
    width: 53%;
    > picture {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    img {
        width: 90%;
    }
  }
  @media (max-width: 1000px) {
    padding: 50px 6vw;
    flex-direction: column;
    > div {
        width: 100%;
        padding-right: 0px;
        margin-bottom: 20px;
    }
    > div:last-child{
        width: 100%;
        margin-bottom: 0px;
        margin-top: 30px;
        align-items: center;
        > picture {
          justify-content: center;
        }
        img {
            width: 80%;
        }
    }
  }
  @media (max-width: 500px) {
    padding: 50px 10px;
  }
  @media (min-width: 1600px) {
    padding-left: calc(calc(100% - 1404px) / 2);
    padding-right: calc(calc(100% - 1420px) / 2);
  }
`;

const Button = styled.button`
  margin-top: ${(props) => props.marginTop ? props.marginTop : '70px'};
  width: ${(props) => props.tryFree ? '200px' : '230px'};
  background-color: ${(props) => props.tryFree ? '#fff' : '#1573B9' };
  color: ${(props) => props.tryFree ? '#1573B9' : '#fff' };
  padding: ${(props) => props.paddingVertical ? `${props.paddingVertical}` : '14px 10px'};
  border-radius: 40px;
  border: none;
  font-size: 16px;
  font-family: ${BoldFont};
  font-weight: 700;
  cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};
  
  &:hover, &:focus, &:active {
    outline: none;
  }
  
  @media (max-width: 1000px) {
   margin-top: ${(props) => props.tryFree ? props.marginTop : '35px'};
  }
`;

const ContactButton = styled.button`
  margin-top: 10px
  width: 50%
  background-color: #2296B7;
  color: #fff;
  padding: 20px 10px;
  border-radius: 40px;
  border: none;
  font-size: 16px;
  font-family: ${BoldFont};
  font-weight: 700;
  cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};
  
  &:hover, &:focus, &:active {
    outline: none;
  }
  
  @media (max-width: 1000px) {
    margin-top: ${(props) => props.tryFree ? props.marginTop : '20px'};
  }
  
  @media (max-width: 531px) {
    width: 85%;
  }
`;

const TabContainer = styled.div`
  width: ${(props) => props.setTabWidth ? '85%' : '100%'};
    > div {
        float: left;
        width: 50%;
        padding: 13px 0px;
        font-family: ${BoldFont};
        font-size: 25px;
        color: #fff;
        cursor: pointer;
    }
    div:first-child {
        background-color: ${(props) => props.active ? '#1E76AB' : '#EBF7FE'};
        background-image: ${(props) => props.active ? '': 'linear-gradient(180deg, #EBF7FE 0%, #DBEFFD 100%)'};
        text-align: right;
        padding-right: 35px;
        color: ${(props) => props.active ? '#fff' : '#51576B'};
    }
    div:last-child {
        background-color: ${(props) => !props.active ? '#1E76AB' : '#EBF7FE'};
        background-image: ${(props) => props.active ? 'linear-gradient( #DBEFFD 100%, 180deg, #EBF7FE 0%)': ''};
        padding-left: 35px;
        color: ${(props) => !props.active ? '#fff' : '#51576B'};
    }
    @media (max-width: 500px){
        > div {
            width: 100%;
        }
    }  
`;

const TeamTabContainer = styled.div`
  width: ${(props) => props.setTabWidth ? '85%' : '100%'};
  padding: 0 3%;
  background-color: #fff;
    > div {
        float: left;
        width: 50%;
        padding: 13px 0px;
        font-family: ${BoldFont};
        font-size: 25px;
        color: #fff;
        cursor: pointer;
    }
    div:first-child {
        background-color: ${(props) => props.active ? '#59ABF2' : '#1D4765'};
        text-align: right;
        padding-right: 50px;
        color: ${(props) => props.active ? '#fff' : '#CEC6C6'};
    }
    div:last-child {
        background-color: ${(props) => !props.active ? '#59ABF2' : '#1D4765'};
        padding-left: 50px;
        color: ${(props) => !props.active ? '#fff' : '#CEC6C6'};
    }
    @media (max-width: 500px){
        > div {
            width: 100%;
        }
    }
  @media (max-width: 1000px) {
    width: 100%
  }
  @media (max-width: 600px) {
    padding: 0% 0%;
    display: flex;
    flex-direction: column;
  }
`;

const CenterContainer = styled.div`
  float: left;
  width: 100%;
  background-color: #F6F6F8;
  padding: 50px 90px 50px 98px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
    .planTableContainer, .teamPackage {
        display: flex;
        align-items: center;
        flex-direction: column;
        width:100%;
    }
    .startText {
      width: 80%;
      padding: 50px 0;
      font-size: 24px;
      font-family: ${BoldFont};
      color: #404555;
      font-weight: 5s00;
      line-height: 1.6;
      text-align: center;
    }
    .contactContainer {
      width: 85%;
      background-color: #fff;
      text-align: center;
      margin-top: 20px;
      padding: 50px 30px;
      h4 {
      line-height: 1.6;
      font-family: ${MediumFont};
      color: #404555;
      }
     .hr {
        border-top: 1.5px solid #3F4556;
        margin: 60px 15px 0 15px;
     }
    } 
  @media (max-width: 1000px) {
    padding: 50px 6vw;
    .contactContainer {
      width: 100%;
    }
  }
  @media (max-width: 500px) {
    padding: 50px 10px;
  }
  @media (min-width: 1600px) {
    padding-left: calc(calc(100% - 1404px) / 2);
    padding-right: calc(calc(100% - 1420px) / 2);
  }
`;

const PlanTitleSection = styled.div`
  float: left;
  width: 85%;
  padding: 15px 0px 40px 0px;
  h1 {
    text-align: center;
    font-size: 37px;
    color: #404555;
    font-family: ${BoldFont};
    margin-top: 15px;
    line-height: 40px;
    font-weight: 700;
  },
  p {
    color: #404555  ;
    font-size: 22px;
    text-align: center;
    font-family: ${MediumFont};
  }
  @media (max-width: 1000px){
    width: 100%
  }  
`;

const PlanSection = styled.div`
  float: left;
  width: ${({width}) => width ? "97%" : '85%'};
  background-color: #fff;
  display: flex;
  align-items:flex-start;
  flex-wrap: wrap;
  justify-content: ${({justify}) => justify ? "flex-start" : 'center'};
  padding-bottom: ${({width}) => width ? "20px" : '0px'};
  hr {
    width: ${({width}) => width ? '100%' : '92%'};
    border-top: 1.5px solid #3F4556;
    margin: 60px 0px;
  }
  @media (max-width: 1000px){
    width: 100%
  }
  @media (max-width: 600px){
    align-items: center;
  }
`;

const Plan = styled.div`
  width: 33.323%;
  display: block;
  flex-direction: column;
  align-items: center;
  min-height: 380px;
  position: relative;
  .selected{
    z-index: 10;
    width: 105px;
    height: 29px;
    padding: 2px 4px;
    font-size: 11px;
    text-align: center;
    color: #fff;
    font-weight: bold;
    border-bottom: 30px solid green;
    border-right: 30px solid transparent;
    border-left: 30px solid transparent;
    transform: rotate(45deg);
    position: absolute;
    right: -27px;
    top: 67px;
    >div{
      width: 100%;
      display: flex;
      align-items: center;
      position: absolute;
      top: 3px;
      font-family: ${BoldFont};
    }
  }
  > div:first-child {
    background-color: ${(props) => props.background};
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 24px;
    text-align: center;
    font-family: ${MediumFont}; 
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3F4556;
    h1 {
      display: inline-block;
      font-size: ${({width}) => width ? '60px' : '70px'};
      margin: 0;
      font-family: ${BoldFont}
      font-weight: 500;
    }
  }
  .buttons {
    display: ${({display}) => display ? "flex" : 'block'};
    padding-left: 27px; 
     @media (max-width: 600px){
        display: flex;
        padding-left: 0;
     }
  } 
  .amount {
    padding: 40px 0px 30px 0px;
    align-items: flex-end;
    span {
      display: inline-block;
      font-family: ${BoldFont};
      position: relative;
      bottom: 10px;
      padding-left: 10px;
      font-size: 18px;
    }
  }
  p {
      text-align: center;
      font-size: 20px;
      font-family: ${RegularFont};
      margin: 0;
      padding: 10px 1px 25px;
      width: 85%;
      margin: 0 auto;
      min-height: 63px;
    }
  @media (max-width: 600px){
    width: 100%;
    margin-bottom: 35px;
  }
  .current{
    position: absolute;
    right: 2px;
    font-family: ${RegularFont};
    font-size: 15px;
    border: 1px solid #fff;
    top: 2px;
    padding: 1px 5px;
    color: #fff;
    border-radius: 15px;
  }
`;

const GetStartedButton = styled.button`
  margin-top: 15px;
  width: 80%;
  background-color: transparent;
  color: ${(props) => props.borderColor ? props.borderColor : '#3F4556'};
  padding: 20px 10px;
  border-radius: 40px;
  font-size: 16px;
  border: 1px solid #3F4556;
  border-color: ${(props) => props.borderColor ? props.borderColor : '#3F4556'};
  font-family: ${MediumFont};
  font-weight: 700;
  position: absolute;
  bottom: 0px;
  
  &:hover, &:focus, &:active {
    outline: none;
  }
  
  @media (max-width: 1000px) {
   margin-top: 35px;
  }  
`;

const DropDownContainer = styled.div`
  float: left;
  width: ${(props) => props.width ? props.width : '85%'};
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 40px;
    span {
      color: #1E76AB;
      font-size: 20px;
      display: inline-block;
      font-family: ${MediumFont};
      cursor: pointer;
      font-weight: 500;
    }
    .dropIcon {
      margin-top: 14px;
      margin-left: 7px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #1E76AB;
      float: left;
      font-weight: 500;
    }
  @media (max-width: 1000px){
    width: 100%;
  }  
`;

const TableContainer = styled.div`
  float: left;
  width: ${(props) => props.width ? props.width : '85%'};;
  padding 0 3% 30px 3%;
  background-color: #FFFFFF;
    .tableHeader {
      float: left;
      width: 100%;
      height: 60px;
      background-color: #E8F2F6;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
        width: 17%;
        color: #3F4556;
        font-size: 18px;
        font-family: ${RegularFont};
        text-align: center;
        margin-top: 4px;
        font-weight: 400;
      }
      > div:first-child {
        width: 49%;
        color: #3F4555;
        font-family: ${BoldFont};
        font-size: 20px;
        padding-left: 40px;
        text-align: left;
        font-weight: 600;
      }
    }
  .enterpriseHeader {
      > div: first-child {
          width: 50%;
          text-align: center;
          text-align: left;
          padding-left: 40px;
          color: #3F4555;
          font-family: ${BoldFont};
          font-size: 20px;
          font-weight: 600;
      }
      > div: last-child {
          width: 50%;
          text-align: center;
          text-align: left;
          padding-left: 40px;
          color: #3F4555;
          font-family: ${BoldFont};
          font-size: 20px;
          font-weight: 600;
      }
  }  
  @media (max-width: 1000px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    padding: 0px;
    .tableHeader {
      > div:first-child {
        padding-left: 10px;
      }
    }
  }
  @media (max-width: 450px) {
    .tableHeader {
      > div:first-child {
        width: 34%
        font-size: 16px;
      }
      > div {
        width: 22%;
        font-size: 16px;
      }
    }
  }
  @media (max-width: 450px) {
    .enterpriseHeader {
      > div:first-child {
        width: 50%
        font-size: 17px;
        padding-left: 10px;
      }
      > div:last-child {
        width: 50%
        font-size: 17px;
        padding-left: 10px;
      } 
    } 
  }  
`;

const EmployeeEnterprise = styled.div`
  float: left;
  width: 50%;
  padding: 0 15px;  
`;

const AdminEnterprise = styled.div`
  float: right;
  width: 50%;
  padding: 0 15px;  
`;

const Table = styled.div`
  float: left;
  width: 100%;
  color: #404555;
  padding-bottom: ${(props, paddingBottom) => props.padding ?'0': paddingBottom ? paddingBottom : '15px'};
  .tableRow {
    float: left;
    width: 100%;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
     > div {
        width: 17%;
        color: #31adf8;
        font-size: 16px;
        font-family: ${MediumFont};
        text-align: center;
        padding: 15px 0px; 
      }
      > div:first-child {
        width: ${(props) => props.setWidth ? '83%' : '49%'};
        color: #404555;
        font-family: ${MediumFont};
        font-size: 16px;
        padding-left: 40px;
        text-align: left;
      }
      img {
        width: 22px;
        height: 21px;
      }
    .features {
      display: flex;
      align-items: center;
      padding-left: 15px !important;
      span:last-child {
        padding-left: 7px;
      }
    }
  }
  .evenRow {
    background-color: #EAF2F6;
  }
  .enterpriseRow {
    background-color: #EAF2F6;
  }
  .enterPriseRow {
    justify-content: space-between;
    > div:first-child {
        width: 65%;
    }
    > div:last-child {
        width: 35%;
    }
  }
  .packageHeading {
    padding-left: 40px;
    padding-bottom: 10px;
    font-family: ${BoldFont};
    font-size: 18px;
  }
  .packageHeadingEnterprise {
    color: #fff;
    padding: 20px 0 17px 40px;
    font-family: ${BoldFont};
    font-size: 18px;
  }
  @media (max-width: 600px){
    .tableRow {
       > div:first-child {
          padding-left: 10px;
       }
    }
  }
  @media (max-width: 400px){
    .tableRow {
       > div:first-child {
          font-size: 14px;
          padding: 0 0 0 5px;
       }
       img {
          width: 13px;
          height: 13px;
       }
    }
  }
  @media (max-width: 600px){
     .packageHeading {
          padding-left: 10px;
     }
  }
  @media (max-width: 600px){
     .packageHeadingEnterprise {
          padding: 20px 0 17px 11px;
     }
  }
  @media (max-width: 450px){
     .packageHeadingEnterprise {
        padding: 20px 0 17px 10px;
        font-size: 14px;
     }
  }
  @media (max-width: 450px){
    .tableRow {
       > div {
          width: 20%;
       }
       > div:first-child {
          width: 40%;
       } 
    }
    .enterPriseRow {
      > div:first-child {
         width: 65%;
      }
      > div:last-child {
        width: 35%;
      }
  }
  }
  @media (max-width: 410px){
    .features {
       span:last-child {
        font-size: 14px;
      }
    }
  }
`;

const EnterpriseContainer = styled.div`
  float: left;
  width: 85%;
  padding: 0px 0px 40px 0px;
  margin-top: 35px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > div:first-child {
      background-color: #71A147;
      height: 75px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 30px;
      text-align: center;
      font-family: ${MediumFont};
      @media (max-width: 400px) {
        height: auto;
        padding: 15px;
      }
  }
  @media (max-width: 1000px){
    width: 100%;
  }
`;

const FormContainer = styled.div`
  float: left;
  width: 90%;
  padding: 40px 0px 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    color: #2B281E;
    font-size: 22px;
    text-align: center;
    font-family: ${RegularFont};
    word-break: break-word;
    width: 100%;
  }
  .formSection {
    width: 85%;
    float: left;
    padding: 20px 10px;
    > div {
      padding-top: 20px;
    }
    span {
      font-size: 16px;
      color: #2B281E;
      font-family: ${RegularFont}
    }
    input {
      width: 100%;
      height: 55px;
      border: 1px solid #2B281E;
      border-radius: 4px;
      margin-top: 10px;
      outline: none;
      padding: 0px 10px;
      color: #2B281E;
      :focus {
        outline: none;
      }
    }
  @media (max-width: 450px){
    width: 100%;
  }  
  }
`;

const PosterContainer = styled.div`
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 35px 0px;
  > div {
    width: 40%;
      img {
        width: 90%;
        float: right;
      }
     > h1 {
        font-size: 40px;
        color: #3F4556;
        font-family: ${BoldFont};
        letter-spacing: 0;
        margin-top: 15px;
        line-height: 60px;
        font-weight: 700;
      }
    > p {
        font-family: ${MediumFont};
        font-size: 21px;
        line-height: 32px;
        color: #3f4557;
        letter-spacing: 0;
        font-weight: 400;
        margin-bottom: 15px;
     }
    > span {
        font-family: ${MediumFont};
        font-size: 21px;
        line-height: 32px;
        color: #3f4557;
        letter-spacing: 0;
        font-weight: 400;
        color: #1e76ab;
        margin-top: 50px;
        display: flex;
        cursor: pointer;
        align-items: center;
        img {
          width: auto;
          margin-left: 5px;
        }
    }  
  }
  > div:first-child {
    width: 60%;
  }
  @media (max-width: 1000px){
    flex-direction: column;
    > div {
      width: 100%; 
      img {
        width: 80%;
        float: unset;
      }
    }
    > div:first-child {
      width: 100%;
      text-align: center;
      padding-bottom: 50px;
    }
  }
`;

const AddOnContainer = styled.div`
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 35px 0px;
  margin-top: 15px;
   > div {
    > h1 {
        font-size: 40px;
        color: #404555;
        font-family: ${BoldFont};
        letter-spacing: 0;
        margin-top: 15px;
        line-height: 60px;
        font-weight: 700;
      }
    > p {
        font-family: ${RegularFont};
        font-size: 21px;
        line-height: 32px;
        color: #3f4557;
        letter-spacing: 0;
     }
   }
   .cards {
     display: flex;
     flex-direction: row;
     width: 100%;
   }
   @media (max-width: 1000px) {
    flex-direction: column;
    > div {
      width: 100%;
    }
    > div:last-child {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 30px;
    }
   }
`;

const QuestionContainer= styled.div`
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 35px 0px;
  margin-top: 15px;
  strong {
    font-family: ${BoldFont};
  }
   > div {
    > h1 {
        font-size: 40px;
        color: #404555;
        font-family: ${BoldFont};
        letter-spacing: 0;
        margin-top: 15px;
        line-height: 60px;
        font-weight: 700;
      }
    > p {
        font-family: ${RegularFont};
        font-size: 21px;
        line-height: 32px;
        color: #3f4557;
        letter-spacing: 0;
     }
   }
   .cards {
     display: flex;
     flex-direction: row;
   }
   .questionContainer {
     display: flex;
     flex-direction: column;
     width: 80%;
   }
   @media (max-width: 1000px) {
    flex-direction: column;
    > div {
      width: 100%;
    }
    > div:last-child {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 30px;
    }
   }
   @media (max-width: 600px){
    .questionContainer{
      width: 100%;
    }
   }
`;

const AddOnCard = styled.div`
  float: left;
  margin: 15px 10px;
  min-height: 400px;
  width: calc(100% - 20px);
  border-radius: 4px;
  background-color: #fff;
  max-width: 500px;
  padding: 20px 27px;
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
      
  > div {
    display: block;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    > img {
      width: 50px;
      height: 50px;
    }
    > h1 {
      font-family: ${BoldFont};
      font-size: 20px;
      font-weight: 500;
      color: #404555;
      text-align: center;
    }
    > h2 {
      font-family: ${BoldFont};
      font-size: 20px;
      font-weight: 500;
      color: #404555;
      text-align: center;
    }
    > p {
      font-family: ${MediumFont};
      font-size: 14px;
      line-height: 25px;
      color: #3f4557;
      letter-spacing: 0;
      font-weight: 400;
      min-height: 75px;
    }
  }
  .button {
    width: 100%;
    display: flex;
    align-items: center;
    > div {
      width: 80%;
      height: 45px;
      padding-top: 13px;
      margin-bottom:10px;
      border-radius: 40px;
      background-color: #3A74A7;
      color: #fff;
      font-family: ${BoldFont};
      font size: 15px;
      cursor: pointer;
    }
    > p {
      font-family: ${MediumFont};
      font-size: 14px;
      padding-bottom: 38px;
      line-height: 32px;
      color: #3f4557;
      letter-spacing: 0;
      font-weight: 400;
      min-height: 65px;
      > span {
        color: #3A74A7;
        font-family: ${MediumFont};
      }
    }
  }
  .priceContainer {
    flex-direction: column;
    width: 85px;
     h1 {
      margin: 0px 0px 0px 0px;
      font-size: 25px;
      font-family: ${BoldFont}
     }
     p {
      font-size: 12px;
      font-family: ${MediumFont}
      margin: 0px;
      line-height: 14px;
      margin-left: 5px;
      margin-top: 5px;
      text-align: center;
     }
  }
  @media (max-width: 550px) {
    margin: 20px 0px;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    .priceContainer {
      margin-top: 20px;
    }  
  }
  @media (max-width: 1000px) {
    max-width: 272px;
    min-height: 417px; 
  }
`;

const QuestionCard = styled.div`
  float: left;
  margin: 15px 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
    > p {
    font-family: ${BoldFont};
    font-size: 18px;
    line-height: 30px;
    color: #3f4557;
    letter-spacing: 0;
    font-weight: 500;
    margin: 0px;
    text-align: left
    a:hover {
      text-decoration: none;
    }
  }
  >span {
    height: 30px;
    width: 30px;
    min-width: 30px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    > i {
      color: #6E6E6E;
      font-size: 22px;
      }
    }
  }
  .accordion {
    width: 100%;
    > p {
      font-family: ${MediumFont};
      font-size: 16px;
      line-height: 22px;
      text-align: left;
      font-weight: 100;
    }
  }
  > div:first-child {
    cursor: pointer;
  }
  .rotateIcon {
    transform: rotate(180deg);
  }
`;

const FreeSection = styled.div`
  background: url(../../images/HomePageV2/tailored-background.png);
  padding: 50px 90px 50px 98px;
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h3 {
    font-size: 32px;
    font-family: ${BoldFont};
    font-weight: 500;
    color: #fff;
    margin-top: 30px;
    text-align: center;
  }
  p {
    font-size: 18px;
    line-height: 28px;
    color: #fff;
    font-family: ${RegularFont}
    font-weight: 400;
    text-align: center;
    max-width: 700px;
    margin: 20px 0px;
  }
  > div {
    padding: 80px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h1 {
      font-size: 38px
      color: #fff;
      font-family: ${BoldFont}
      font-weight: 700;
      text-align: center;
    }
  }
  @media (max-width: 1000px) {
    padding: 50px 6vw;
  }
  @media (max-width: 500px) {
    padding: 50px 10px;
  }
  @media (min-width: 1600px) {
    padding-left: calc(calc(100% - 1404px) / 2);
    padding-right: calc(calc(100% - 1420px) / 2);
  }
`;

export {
  Section,
  Button,
  ContactButton,
  TabContainer,
  TeamTabContainer,
  CenterContainer,
  PlanTitleSection,
  PlanSection,
  Plan,
  GetStartedButton,
  DropDownContainer,
  TableContainer,
  EmployeeEnterprise,
  AdminEnterprise,
  Table,
  EnterpriseContainer,
  FormContainer,
  PosterContainer,
  AddOnContainer,
  QuestionContainer,
  AddOnCard,
  QuestionCard,
  FreeSection
};